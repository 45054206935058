import { ChannelBanner } from './Banner'
import { WelcomeBanner } from './Banner/WelcomeBanner'
import { Diagnosis } from './Diagnosis/Diagnosis'
import { Interview } from './Interview/Interview'
import { Recommend } from './Recommend/Recommend'
import { WeeklyRankings } from './WeeklyRankings/WeeklyRankings'
import { GetSidebarResponse } from '@/types/sidebar'
import { ItemListJsonld } from '@/components/script/seo/ItemListJsonld'
import { UserInfo, CourseId } from '@/types'
import { useRouter } from 'next/router'
import { useUser } from '@/hooks/useUser'

interface Props {
  data: GetSidebarResponse
}

export const SidebarPc: React.FC<Props> = ({ data }) => {
  const user = useUser()
  const showWelcomeBanner = (user: UserInfo) => {
    // ユーザーデータがロード中の間はバナーを表示しないことで、ちらつきを防ぐ
    if (user === undefined) return false
    if (!user) return true
    return (
      user.course?.id !== CourseId.silver &&
      user.course?.id !== CourseId.gold &&
      !user.used_free_coupon
    )
  }
  const router = useRouter()
  const showFlierChannelBanner = (user: UserInfo) => {
    if (!user || user?.course?.id < CourseId.silver || user?.biz_group_id != 0)
      return false
    if (router.pathname === '/channel') return false
    return true
  }
  return (
    <div className="w-[278px] h-full text-[15px] align-top bg-fl-gray2 pc">
      {/* ゴールド7日無料LPバナー */}
      {showWelcomeBanner(user) && <WelcomeBanner />}

      {/* flierチャンネル */}
      {showFlierChannelBanner(user) && <ChannelBanner />}

      {/* 今週の要約ランキング */}
      <WeeklyRankings summaries={data?.sidebar?.rankings} />

      {/* おすすめの要約診断 */}
      <Diagnosis diagnosis={data ? data.sidebar?.diagnosis : null} />

      {/* イチオシの本 */}
      <Recommend pickup={data ? data.sidebar?.pickup : null} />
      {/* おすすめのインタビュ */}
      <Interview interview={data?.sidebar?.interview} />

      <ItemListJsonld
        items={data?.sidebar?.rankings?.map((ranking) => {
          return {
            url: `https://www.flierinc.com/summary/${ranking.id}`,
          }
        })}
      />
    </div>
  )
}
