/* eslint tailwindcss/no-custom-classname: off */
interface Props {
  title: string
  id: string
}
export const SummaryImage: React.FC<Props> = ({ title, id }) => {
  return (
    <div className="summary-image">
      <img
        src={`${process.env.IMG_SERVER}/summary/${id}_cover_100.jpg`}
        alt={title}
      />
    </div>
  )
}
