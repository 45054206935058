/* eslint tailwindcss/no-custom-classname: off */
import { Summary } from '@/types/summary'
import { MoreLink } from '../Link/MoreLink'
import { Summaries } from './Summaries'
import { SummarySkeleton } from './SummarySkelton'
import { TabbarRankingIcon } from '@/components/icon'

interface Props {
  summaries?: Summary[]
}

export const WeeklyRankings: React.FC<Props> = ({ summaries }) => {
  return (
    <>
      <div className="title-group mb10 mt30 indent-1.5">
        <div className="sidebar-title flexbox" id="icon-ranking">
          <TabbarRankingIcon className="w-[30px] h-[30px] text-fl-blue" />
          <div className="text-[17px] text-[#222222] title">
            今週の要約ランキング
          </div>
        </div>
      </div>
      <div className="ml10 mb20">
        {summaries ? <Summaries summaries={summaries} /> : <SummarySkeleton />}
        <div className="right">
          <MoreLink url="/summary/ranking?tab=weekly" />
        </div>
      </div>
    </>
  )
}
