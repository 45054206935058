import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useUser } from '@/hooks/useUser'
import { COOKIE_KEY_LIMITED_URL } from '@/utils/constant'
import { parseCookies, setCookie } from 'nookies'

// NOTE: 障害暫定対応。恒久対応され次第useEffectごと削除する。
// URL配布型かつandroidのユーザーで、/summaryや/pickupのURLを開くとアプリ起動してしまうため、/ltをつけたURLにリダイレクトする。
// @see https://github.com/flier-inc/www4/pull/2033/
export const useRedirectLimitedUrlUser = (): void => {
  const router = useRouter()
  const user = useUser()

  // リンク遷移前にURL配布型か判別して/ltつけてリダイレクトする
  useEffect(() => {
    const redirectForLimitedUrlUser = (url) => {
      const cookies = parseCookies()
      // user.emailでURL配布型ユーザーの判定をせずにCOOKIEで判定する
      // userの取得には画面描画後に数秒遅延があるため、描画途中で遷移された場合に/ltをつけることができないため。
      if (cookies[COOKIE_KEY_LIMITED_URL]) {
        // URL配布型のCOOKIEがあるのにURL配布型ユーザーではない場合、次回から/ltをつけさせないためCOOKIEを削除。
        if (user && !user?.email?.includes('limited_url.flierinc')) {
          setCookie(null, COOKIE_KEY_LIMITED_URL, null, {
            maxAge: 0,
            path: '/',
          })
        }
        const res = url.match(
          /^\/(summary|pickup|interview|column|diagnosis|recommend|playlists)\//
        )
        if (res && res[0] != '') {
          const href = url.replace(res[0], '/lt' + res[0])
          router.push(href)
          // 本来のルーティングをキャンセル
          // @see https://github.com/vercel/next.js/discussions/32231
          throw 'Abort route change'
        }
      }
    }
    router.events.on('routeChangeStart', redirectForLimitedUrlUser)
    return () =>
      router.events.off('routeChangeStart', redirectForLimitedUrlUser)
  }, [router.asPath, user])
}
