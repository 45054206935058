import Link from 'next/link'
import { Summary as SummaryProps } from '@/types/summary'
import { RankBadge } from '@/components/summary'
import { SummaryImage } from '@/components/summary/SummaryImage'
import { SummaryDetail } from '@/components/summary/SummaryDetail'

interface Props {
  summary: SummaryProps
}

export const Summary: React.FC<Props> = ({ summary }) => {
  return (
    <Link href={`/summary/${summary.id}`}>
      <div className="flex w-full !no-underline">
        <div className="relative w-[45%]">
          <div className="ml-[15px]">
            <RankBadge rank={summary.rank} />
          </div>
          <SummaryImage id={summary.id} title={summary.title} size="150" />
        </div>
        <div className="w-[45%]">
          <SummaryDetail summary={summary} />
        </div>
      </div>
    </Link>
  )
}
