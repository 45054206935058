/* eslint tailwindcss/no-custom-classname: off */
import { useGetUserBookPercentQuery } from '@/stores/apiService'
import { Summary } from '@/types'
import { getReadStatus } from '@/features/global/util'
import { ReadStatus } from '@/components/label/ReadStatus'
import { Free } from '@/components/label/Free'

interface Props {
  summary: Summary
}
export const SummaryButton: React.FC<Props> = ({ summary }) => {
  const { data } = useGetUserBookPercentQuery()
  const readStatus = getReadStatus(data, String(summary.id))
  return (
    <div className="summary-button" data-id={summary.id}>
      <ReadStatus status={readStatus} className="mr-1" />
      {summary.free === 1 && <Free className="mr-1" />}
    </div>
  )
}
