import useSWR from 'swr'
import { GetSidebarResponse, SidebarFlag } from '@/types/sidebar'
import { SidebarPc } from '../Sidebar/SidebarPc/SidebarPc'
import { SidebarSp } from '../Sidebar/SidebarSp/SidebarSp'
import { useRedirectLimitedUrlUser } from '@/hooks/useRedirectLimitedUrlUser'

interface Props {
  sidebar: SidebarFlag
  children?: React.ReactNode
}

export const Container: React.FC<Props> = ({ sidebar, children }) => {
  useRedirectLimitedUrlUser()
  const { data: sidebarData } = useSWR<GetSidebarResponse>('/api/sidebar')

  return (
    <div className="flex w-full">
      <div className="flex-1"></div>
      <div className="container">
        {children}
        {sidebar.pc && <SidebarPc data={sidebarData} />}
        {sidebar.sp && <SidebarSp data={sidebarData} />}
      </div>

      {sidebar.pc ? (
        <div className="flex-1 bg-fl-gray2"></div>
      ) : (
        <div className="flex-1"></div>
      )}
    </div>
  )
}
