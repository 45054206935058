/* eslint tailwindcss/no-custom-classname: off */
import { Feature } from '@/types/sidebar'
import { MoreLink } from '../Link/MoreLink'
import { FeatureSm } from '../FeatureSm'
import { StarIcon } from '@/components/icon'

interface Props {
  pickup?: Feature
}
export const Recommend: React.FC<Props> = ({ pickup }) => {
  return (
    <>
      <div className="title-group indent-1">
        <div className="sidebar-title flexbox" id="icon-recommend">
          <StarIcon className="w-[30px] h-[30px] text-fl-blue" />
          <div className="text-[17px] text-[#222222] title">イチオシの本</div>
        </div>
      </div>
      <div className="ml10 mb20">
        <FeatureSm feature={pickup} />
        <div className="right">
          <MoreLink url="/pickup/list/1" />
        </div>
      </div>
    </>
  )
}
