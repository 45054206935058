import Link from 'next/link'

interface Props {
  id: string
}
export const SummaryLink: React.FC<Props> = ({ id }) => {
  return (
    <Link href={`/summary/${id}`}>
      <div>
        <span>{`/summary/${id}`}</span>
      </div>
    </Link>
  )
}
