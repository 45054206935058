import Link from 'next/link'
import { Feature as FeatureProps } from '@/types/sidebar'
import { H1 } from '@/components/label/H1'
import { ShowAllButton } from '@/components/button/ShowAllButton'
import { Feature } from './Feature'

interface Props {
  interviews?: FeatureProps[]
}

export const Interviews: React.FC<Props> = ({ interviews }) => {
  if (!interviews) return null
  return (
    <div className="pl-3 mt-8">
      <div className="mb-8 text-center">
        <H1 title="インタビュー" />
      </div>
      <div className="flex flex-wrap justify-center">
        {interviews.slice(0, 4).map((interview) => (
          <Link
            href={interview.url}
            key={interview.id}
            className="px-1 w-1/2 !no-underline"
          >
            <Feature feature={interview} />
          </Link>
        ))}
      </div>
      <div className="mt-12 text-center">
        <ShowAllButton href="/interview/list/1" />
      </div>
    </div>
  )
}
