/* eslint tailwindcss/no-custom-classname: off */
import Link from 'next/link'
import { Feature } from '@/types/sidebar'
import dayjs from 'dayjs'

interface Props {
  feature?: Feature
}
export const FeatureSm: React.FC<Props> = ({ feature }) => {
  if (!feature) return null
  return (
    <div className="feature-sm" id={`${feature?.id}`}>
      <div className="feature-image">
        <img
          src={`${process.env.IMG_SERVER}/feature/${feature?.id}_banner.jpg`}
          alt={feature?.title}
        />
      </div>
      <div className="feature-detail">
        <div className="feature-title">{feature?.title}</div>
        <div className="feature-update">
          {dayjs(feature?.released).format('YYYY.MM.DD')} update
        </div>
      </div>
      <Link href={feature?.url}>
        <div>
          <span>{feature?.url}</span>
        </div>
      </Link>
    </div>
  )
}
