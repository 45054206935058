import { GetSidebarResponse } from '@/types/sidebar'
import { Diagnoses } from './Diagnoses'
import { Interviews } from './Interviews'
import { Pickups } from './Pickups'
import { WeeklyRankings } from './WeeklyRankings'

interface Props {
  data: GetSidebarResponse
}

export const SidebarSp: React.FC<Props> = ({ data }) => {
  return (
    <div className="sp">
      <div className="my-8 border-b"></div>
      <WeeklyRankings rankings={data?.sidebar?.rankings} />
      <Diagnoses diagnoses={data?.sidebar?.diagnoses} />
      <Pickups pickups={data?.sidebar?.pickups} />
      <Interviews interviews={data?.sidebar?.interviews} />
      <div className="my-12 border-b"></div>
      <div className="my-16"></div>
    </div>
  )
}
