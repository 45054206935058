import css from 'styled-jsx/css'
import Link from 'next/link'

const styles = css`
  button {
    position: relative;
    color: #fff;
    padding-bottom: 4px;
    height: 50px;
    padding: 0 30px;
    vertical-align: middle;
    background-color: #293c6f;
    border-bottom: 2px solid #0d1733;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
  }
  button:focus {
    outline: none;
  }
  button:active {
    outline: none;
  }
  button:hover {
    outline: none;
    background-color: #0d1733;
  }
  button:visited {
    outline: none;
  }
  button:hover {
    color: #fff;
  }
  #arrow-left-white {
    content: '';
    width: 7px;
    height: 7px;
    border: 0px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 2px);
    right: 10px;
  }
`

interface Props {
  href: string
}

export const ShowAllButton: React.FC<Props> = ({ href }) => {
  return (
    <>
      <Link href={href}>
        <button type="button" className="rounded">
          <span className="text-sm">すべてを見る</span>
          <span id="arrow-left-white"></span>
        </button>
      </Link>
      <style jsx>{styles}</style>
    </>
  )
}
