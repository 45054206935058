// next-seoに@type:ItemListのJsonldが存在しないため自作

interface Item {
  url: string
}
interface Props {
  items?: Item[]
}

export const ItemListJsonld: React.FC<Props> = ({ items }) => {
  if (!items) return null
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `{
          "@context": "http://schema.org",
          "@type": "ItemList",
          "itemListElement": [
          ${items.map(
            (item, i) =>
              `{
              "@type": "ListItem",
              "position": "${i + 1}",
              "url": "${item.url}"
              }`
          )}
          ]
        }`,
      }}
    />
  )
}
