/* eslint tailwindcss/no-custom-classname: off */
import { Summary } from '@/types/summary'
import { SummaryLink, RankBadge, SummaryImage } from './'
interface Props {
  summary: Summary
}
export const SummaryXs: React.FC<Props> = ({ summary }) => {
  const { id, rank, title } = summary
  return (
    <div className="summary-xs" id={`${id}`}>
      <RankBadge rank={rank} />
      <SummaryImage id={id} title={title} />
      <SummaryLink id={id} />
    </div>
  )
}
