import { useGetUserInfoQuery } from '@/stores/apiService'

/* eslint-disable-next-line */
export const useUser = () => {
  const { data } = useGetUserInfoQuery()
  if (data?.result) {
    // GTMの変数で利用
    localStorage.setItem('flier_id', `${data.user_id}`)
  }

  return data
}
