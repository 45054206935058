import Link from 'next/link'
import { Feature as FeatureProps } from '@/types/sidebar'
import { H1 } from '@/components/label/H1'
import { ShowAllButton } from '@/components/button/ShowAllButton'
import { Feature } from './Feature'

interface Props {
  pickups?: FeatureProps[]
}

export const Pickups: React.FC<Props> = ({ pickups }) => {
  if (!pickups) return null
  return (
    <div className="pl-3 mt-8">
      <div className="mb-8 text-center">
        <H1 title="イチオシの本" />
      </div>
      <div className="flex flex-wrap justify-center">
        {pickups.slice(0, 4).map((pickup) => (
          <Link
            href={pickup.url}
            key={pickup.id}
            className="px-1 w-1/2 !no-underline"
          >
            <Feature feature={pickup} />
          </Link>
        ))}
      </div>
      <div className="mt-12 text-center">
        <ShowAllButton href="/pickup/list/1" />
      </div>
    </div>
  )
}
