/* eslint tailwindcss/no-custom-classname: off */
export const WelcomeBanner: React.FC = () => {
  return (
    <div className="free pa10">
      <a href="/welcome?via=sidebanner">
        <img
          src={`${process.env.IMG_SERVER}/welcome/7days_gold_211022_pc.png?20230720`}
          className="w-[258px] h-[62px]"
          alt="7日間無料でお試し！"
        />
      </a>
    </div>
  )
}
