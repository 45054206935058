/* eslint tailwindcss/no-custom-classname: off */
import Link from 'next/link'

interface Props {
  url: string
}

export const MoreLink: React.FC<Props> = ({ url }) => {
  return (
    <Link href={url}>
      <div className="arrow-right-orange">
        <span>もっと見る</span>
      </div>
    </Link>
  )
}
