import Link from 'next/link'
import { Diagnosis } from '@/types/sidebar'
import { H1 } from '@/components/label/H1'
import { ShowAllButton } from '@/components/button/ShowAllButton'
import { Feature } from './Feature'

interface Props {
  diagnoses?: Diagnosis[]
}

export const Diagnoses: React.FC<Props> = ({ diagnoses }) => {
  if (!diagnoses) return null
  return (
    <div className="pl-3 mt-8">
      <div className="mb-8 text-center">
        <H1 title="おすすめ要約診断" />
      </div>
      <div className="flex flex-wrap justify-center">
        {diagnoses.slice(0, 4).map((diagnosis) => (
          <Link
            href={diagnosis.url}
            key={diagnosis.id}
            className="px-1 w-1/2 !no-underline"
          >
            <Feature feature={diagnosis} />
          </Link>
        ))}
      </div>
      <div className="mt-12 text-center">
        <ShowAllButton href="/diagnosis/list" />
      </div>
    </div>
  )
}
