/* eslint tailwindcss/no-custom-classname: off */
import { Feature } from '@/types/sidebar'
import { MoreLink } from '../Link/MoreLink'
import { FeatureSm } from '../FeatureSm'
import { ContentPasteSearchIcon } from '@/components/icon'

interface Props {
  diagnosis?: Feature
}

export const Diagnosis: React.FC<Props> = ({ diagnosis }) => {
  return (
    <>
      <div className="title-group indent-1">
        <div className="sidebar-title flexbox" id="icon-diagnosis">
          <ContentPasteSearchIcon className="w-[30px] h-[30px] text-fl-blue" />
          <div className="text-[17px] text-[#222222] title">
            おすすめ要約診断
          </div>
        </div>
      </div>
      <div className="ml10 mb20">
        <FeatureSm feature={diagnosis} />
        <div className="right">
          <MoreLink url="/diagnosis/list" />
        </div>
      </div>
    </>
  )
}
