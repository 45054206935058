import Link from 'next/link'

export const ChannelBanner: React.FC = () => {
  return (
    <div className="p-[10px]">
      <Link href="/channel?from=sidebar_pc">
        <div>
          <img
            src={`${process.env.IMG_SERVER}/sidebar/top_sidebar_flierchannel.png`}
            alt="flierチャンネル"
            width="258"
          />
        </div>
      </Link>
    </div>
  )
}
