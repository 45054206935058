import { H1 } from '@/components/label/H1'
import { Summary as SummaryProps } from '@/types/summary'
import { ShowAllButton } from '@/components/button/ShowAllButton'
import { Summary } from './Summary'

interface Props {
  rankings?: SummaryProps[]
}

export const WeeklyRankings: React.FC<Props> = ({ rankings }) => {
  if (!rankings) return null
  return (
    <div>
      <div className="mb-8 text-center">
        <H1 title="今週の要約ランキング" />
      </div>
      {rankings.slice(0, 3).map((summary, i) => (
        <div key={summary.id}>
          <Summary summary={summary} />
          {i !== 2 && <div className="my-6 border-b"></div>}
        </div>
      ))}
      <div className="mt-12 text-center">
        <ShowAllButton href="/summary/ranking" />
      </div>
    </div>
  )
}
