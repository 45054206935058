/* eslint tailwindcss/no-custom-classname: off */
import { Summary } from '@/types/summary'
import { SummarySm, SummaryXs } from './'
interface Props {
  summaries: Summary[]
}

export const Summaries: React.FC<Props> = ({ summaries }) => {
  return (
    <>
      {summaries.slice(0, 3).map((summary) => (
        <div key={summary.id} className="mt20 pb20 bb-1px-gray">
          <SummarySm summary={summary} />
        </div>
      ))}
      <div className="flexbox mt16 bb-1px-gray">
        {summaries.slice(3, 6).map((summary) => (
          <div key={summary.id} className="relative flex1">
            <SummaryXs summary={summary} />
          </div>
        ))}
      </div>
    </>
  )
}
