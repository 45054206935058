import dayjs from 'dayjs'
import { Feature as FeatureProps } from '@/types/sidebar'

interface Props {
  feature: FeatureProps
}

export const Feature: React.FC<Props> = ({ feature }) => {
  return (
    <div className="mt-4">
      <div className="w-full">
        <img
          src={`${process.env.IMG_SERVER}/feature/${feature.id}_eyecatch_mini.jpg`}
          alt={feature?.title}
        />
      </div>
      <div className="mt-4">
        <div className="text-sm text-[#040000]">{feature?.title}</div>
        <div className="mt-1 text-xs text-fl-gray">
          {dayjs(feature?.released).format('YYYY.MM.DD')} update
        </div>
      </div>
    </div>
  )
}
