/* eslint tailwindcss/no-custom-classname: off */
import { Summary } from '@/types/summary'
import { SummaryLink, SummaryButton, RankBadge, SummaryImage } from './'

interface Props {
  summary: Summary
}

export const SummarySm: React.FC<Props> = ({ summary }) => {
  const { id, rank, title, authors } = summary
  return (
    <div className="summary-sm" id={`${id}`}>
      <RankBadge rank={rank} />
      <SummaryImage id={id} title={title} />
      <div className="summary-detail pc">
        <div className="summary-title">{title}</div>
        <div className="summary-author">
          {authors.map((author) => (
            <span key={author.id} className="pr5">
              {author.name}
            </span>
          ))}
        </div>
        <SummaryButton summary={summary} />
      </div>
      <SummaryLink id={id} />
    </div>
  )
}
